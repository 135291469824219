<template>
  <CModal
    add-content-classes="ccontent"
    :title="$t('lk.modalPassword.title')"
    centered
    :show="passwordModal"
    @update:show="$emit('update:passwordModal', $event)"
  >
    <CInput
      v-model="currentPassword"
      :placeholder="$t('lk.modalPassword.current')"
      type="password"
      class="cinput"
    >
      <template #prepend-content>
        <CIcon
          name="cil-lock-locked"
        />
      </template>
    </CInput>
    <CInput
      v-model="newPassword"
      :placeholder="$t('lk.modalPassword.new')"
      type="password"
      class="cinput"
    >
      <template #prepend-content>
        <CIcon
          name="cil-lock-locked"
        />
      </template>
    </CInput>
    <CInput
      v-model="newPasswordConfirmation"
      :placeholder="$t('lk.modalPassword.repeat')"
      type="password"
      class="cinput mb-0"
    >
      <template #prepend-content>
        <CIcon
          name="cil-lock-locked"
        />
      </template>
    </CInput>

    <template v-slot:footer>
      <CRow class="w-100 m-0">
        <CCol class="px-0" cols="12" xl="5">
          <CLoadingButtonCustom
            color="primary"
            class="cbtn"
            :loading="loadingRequest"
            :disabled="loadingRequest"
            @click.native="save"
          >
            {{ $t('lk.modalPassword.change') }}
          </CLoadingButtonCustom>
        </CCol>
      </CRow>
    </template>
  </CModal>
</template>
<script>
export default {
  name: 'modal-password',
  props: {
    passwordModal: Boolean
  },
  data() {
    return {
      currentPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
      loadingRequest: false
    }
  },
  methods: {
    save() {
      this.loadingRequest = true
      this.$store.dispatch('user/updateSelf', {
        password: this.newPassword,
        password_confirmation: this.newPasswordConfirmation
      })
        .then(({status}) => {
          if (status < 300) {
            this.$toastr.success(this.$t('lk.modalPassword.changeSuccess'))
            this.$emit('update:passwordModal', false)
          }
        })
        .finally(() => {
          this.loadingRequest = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .modal-dialog {
  @include media-breakpoint-down(xs) {
    max-width: none;
    margin-left: 0;
    margin-right: 0;
  }
  @include media-breakpoint-up(sm) {
    max-width: 20rem;
  }
  @include media-breakpoint-up(xl) {
    max-width: 35.75rem;
  }
}

/deep/ .ccontent {
  .cinput .form-control, .cbtn {
    height: 2.75rem;
  }

  .cbtn {
    width: 100%;
  }

  .modal-header, .modal-footer {
    border: 0;
  }

  @include media-breakpoint-down(xs) {
    .modal-header {
      padding: 1.25rem;
    }
    .modal-body {
      padding: 0 1.25rem;
    }
    .modal-footer {
      padding: 1.75rem 1.25rem 3.75rem;
    }
  }
  @include media-breakpoint-up(sm) {
    .modal-header {
      padding: 1.25rem 1.75rem 2rem;
    }
    .modal-body {
      padding: 0 1.75rem;
    }
    .modal-footer {
      padding: 1.75rem;
      padding-bottom: 3.75rem;
    }
  }
  @include media-breakpoint-up(xl) {
    .modal-header {
      padding: 3.25rem;
      padding-bottom: 2.25rem;

      .modal-title {
        font-size: 1.25rem;
      }
    }
    .modal-body {
      padding: 0 3.25rem;
    }
    .modal-footer {
      padding: 3.25rem;
      padding-top: 2rem;
    }
    .cinput {
      margin-bottom: 1.25rem;
    }
  }
}
</style>
