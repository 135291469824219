var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("div", { staticClass: "title-wrap mr-auto" }, [
            _c("h1", { staticClass: "title mb-0 mr-3" }, [
              _vm._v(" " + _vm._s(_vm.$t("lk.header.account")) + " "),
            ]),
            _c("div", { staticClass: "d-flex flex-column" }, [
              _vm.$store.getters["user/telegramVerify"] &&
              !_vm.$store.getters["user/isGuest"]
                ? _c(
                    "div",
                    { staticClass: "d-flex align-items-center" },
                    [
                      _c("CIcon", {
                        staticClass: "text-success",
                        attrs: { name: "cil-check" },
                      }),
                      _c("span", { staticClass: "ml-1 mb-0 verify-message" }, [
                        _vm._v(_vm._s(_vm.$t("lk.account.verifiedTelegram"))),
                      ]),
                      _c(
                        "v-popover",
                        {
                          attrs: {
                            handleResize: false,
                            open: _vm.isOpen,
                            trigger: "manual",
                            container: ".c-app",
                            placement: "bottom",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "rounded-circle tg__popover-wrap d-inline-flex justify-content-center ml-2",
                              on: {
                                click: function ($event) {
                                  _vm.isOpen = !_vm.isOpen
                                },
                              },
                            },
                            [_vm._v(" i ")]
                          ),
                          _c("template", { slot: "popover" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "tooltip-inner d-flex flex-column justify-content-start",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-column align-items-start",
                                  },
                                  [
                                    _vm.userData.telegram?.chat?.id
                                      ? _c("span", [
                                          _vm._v(
                                            " ID: " +
                                              _vm._s(
                                                _vm.userData.telegram.chat.id
                                              ) +
                                              " "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.userData.telegram?.chat?.first_name
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "lk.training.curatorTable.name"
                                                )
                                              ) +
                                              ": " +
                                              _vm._s(
                                                _vm.userData.telegram?.chat
                                                  ?.first_name
                                              ) +
                                              " "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.userData.telegram?.chat?.username
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("general.nick")) +
                                              ": " +
                                              _vm._s(
                                                _vm.userData.telegram?.chat
                                                  ?.username
                                              ) +
                                              " "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                                _vm.userData.getAccess(1, true)
                                  ? _c(
                                      "CLoadingButtonCustom",
                                      {
                                        staticClass: "tg__unbind-btn mt-3",
                                        attrs: {
                                          color: "primary",
                                          loading: _vm.loadingUnbindTg,
                                          disabled: _vm.loadingUnbindTg,
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.unbindTg.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("general.unbind")) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.google2faState
                ? _c(
                    "div",
                    [
                      _c("CIcon", {
                        staticClass: "text-success",
                        attrs: { name: "cil-check" },
                      }),
                      _c("span", { staticClass: "ml-1 mb-0 verify-message" }, [
                        _vm._v(_vm._s(_vm.$t("lk.account.verified2FA"))),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isWeb3Auth
                ? _c(
                    "div",
                    [
                      _c("CIcon", {
                        staticClass: "text-success",
                        attrs: { name: "cil-check" },
                      }),
                      _c("span", { staticClass: "ml-1 mb-0 verify-message" }, [
                        _vm._v(" Web3 "),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
          _vm.showPromoCodeBtn
            ? _c(
                "CButton",
                {
                  attrs: { color: "light" },
                  on: { click: _vm.activateTrends },
                },
                [
                  _c("CIcon", { staticClass: "icon", attrs: { name: "key" } }),
                  _vm._v(" " + _vm._s(_vm.$t("lk.account.promoCodeBtn")) + " "),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex justify-content-between blockWarpper" },
        [
          _c(
            "div",
            [
              _c(
                "CForm",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.save.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("CInput", {
                    ref: "nameInput",
                    staticClass: "cinput",
                    attrs: {
                      value: _vm.username,
                      label: _vm.$t("lk.account.formName"),
                      placeholder: _vm.$t("lk.account.formName"),
                    },
                    on: { input: _vm.nameInputHandler },
                    scopedSlots: _vm._u([
                      {
                        key: "prepend-content",
                        fn: function () {
                          return [_c("CIcon", { attrs: { name: "cil-user" } })]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("CInput", {
                    staticClass: "cinput",
                    attrs: {
                      type: _vm.showEmail ? "text" : "password",
                      label: _vm.$t("lk.account.formEmail"),
                      isValid: () => this.isEmailAvailableToChange(),
                      invalidFeedback: _vm.$t("lk.account.changeEmailHint"),
                      readonly: "",
                      onfocus: "this.removeAttribute('readonly')",
                      autoComplete: "off",
                      placeholder: "email",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "prepend-content",
                        fn: function () {
                          return [_c("CIcon", { attrs: { name: "mail" } })]
                        },
                        proxy: true,
                      },
                      {
                        key: "append-content",
                        fn: function () {
                          return [
                            _vm.showEmail
                              ? _c("CIcon", {
                                  staticClass: "cursor-pointer",
                                  attrs: { name: "eye-close" },
                                  nativeOn: {
                                    click: function ($event) {
                                      _vm.showEmail = !_vm.showEmail
                                    },
                                  },
                                })
                              : _c("CIcon", {
                                  staticClass: "cursor-pointer",
                                  attrs: { name: "eye-open" },
                                  nativeOn: {
                                    click: function ($event) {
                                      _vm.showEmail = !_vm.showEmail
                                    },
                                  },
                                }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.email,
                      callback: function ($$v) {
                        _vm.email = $$v
                      },
                      expression: "email",
                    },
                  }),
                  _vm.google2faState && _vm.showSaveBtn
                    ? _c("CInput", {
                        staticClass: "cinput",
                        attrs: {
                          placeholder: _vm.$t("lk.account.6digitCode"),
                          label: _vm.$t("lk.account.enterCodeFromApp"),
                        },
                        model: {
                          value: _vm.code,
                          callback: function ($$v) {
                            _vm.code = $$v
                          },
                          expression: "code",
                        },
                      })
                    : _vm._e(),
                  _vm.showSaveBtn
                    ? _c(
                        "CLoadingButtonCustom",
                        {
                          staticClass: "cbtn",
                          attrs: {
                            color: "primary",
                            loading: _vm.loadingRequest,
                            disabled: _vm.loadingRequest,
                            type: "submit",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lk.account.save")) + " ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("hr", { staticClass: "separator" }),
              _c(
                "CRow",
                { staticClass: "subactions" },
                [
                  _c(
                    "CCol",
                    { staticClass: "ccol gap-3" },
                    [
                      _c(
                        "CButton",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.changePasswordClick },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("lk.account.changePassword")) +
                              " "
                          ),
                        ]
                      ),
                      _c("confirm-tg", {
                        staticClass: "ml-2 tg-button",
                        attrs: {
                          isRequestPassed:
                            _vm.$store.getters["user/telegramVerify"],
                          isShow: !_vm.$store.getters["user/telegramVerify"],
                          text: _vm.$t("lk.account.connectTelegram"),
                          color: "primary",
                        },
                      }),
                      _c(
                        "CButton",
                        {
                          staticClass: "ml-2",
                          attrs: { color: "primary" },
                          on: { click: _vm.google2FaClick },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("lk.account.configure2FA")) +
                              " "
                          ),
                        ]
                      ),
                      !!_vm.Metamask.ethereum && !_vm.isWeb3Auth
                        ? _c(
                            "CButton",
                            {
                              staticClass: "ml-2",
                              attrs: { color: "primary" },
                              on: { click: _vm.web3ConnectHandler },
                            },
                            [_vm._v(" Web3 ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "d-flex flex-column" }, [
            _c("div", { staticClass: "d-flex cardWrapper" }, [
              _c(
                "div",
                { staticClass: "mr-4 firsCard" },
                [
                  _c(
                    "CCard",
                    {
                      staticClass: "shadow-none referral-info-card mb-0",
                      class: { "referral-active": !_vm.currentTariffEdu },
                      attrs: { color: "second-card-bg" },
                    },
                    [
                      _vm.currentTariffEdu && _vm.userData.tariffIsActive
                        ? _c("LinkGroupSolid", {
                            attrs: {
                              activeTab: _vm.selectedTab,
                              dropdownMenuItems: [
                                {
                                  show: true,
                                  name: _vm.$t("lk.account.tabSubscription"),
                                  iconName: "cil-dollar",
                                },
                                {
                                  show: true,
                                  name: _vm.$t("lk.account.tabTraining"),
                                  iconName: "cilEducation",
                                },
                              ],
                            },
                            on: {
                              selected: function ($event) {
                                _vm.selectedTab = $event
                              },
                            },
                          })
                        : _vm._e(),
                      (
                        _vm.selectedTab == 0
                          ? _vm.userData.tariffIsActive
                          : _vm.currentTariffEdu
                      )
                        ? _c(
                            "CCardBody",
                            { staticClass: "referral-info-card__body" },
                            [
                              _vm.isTariffWeb3
                                ? [
                                    _c(
                                      "div",
                                      { staticClass: "tariff-name mb-1" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "value font-weight-bold",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "text-uppercase",
                                                staticStyle: {
                                                  color: "var(--warning)",
                                                },
                                              },
                                              [_vm._v("WEB3:")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  color: "var(--success)",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("lk.payments.paid")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                : [
                                    _c(
                                      "div",
                                      { staticClass: "tariff-name mb-1" },
                                      [
                                        _c("div", { staticClass: "label" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "lk.account.currentTariff"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "value text-uppercase font-weight-bold",
                                            style:
                                              _vm.selectedTab == 0
                                                ? "color: " +
                                                  _vm.userData.tariff.color
                                                : _vm.currentTariffEdu?.tariff
                                                    ?.id == "standard_edu"
                                                ? "color: #3686F6"
                                                : "color: #FCB81C",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.tariffTitle) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "tariff-expire mb-1" },
                                      [
                                        _c("div", { staticClass: "label" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("lk.account.dueTo")
                                              ) +
                                              " "
                                          ),
                                        ]),
                                        _c("div", { staticClass: "value" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.selectedTab === 0
                                                  ? _vm.dateExpiredFormatted
                                                  : _vm.dateExpiredFormattedTraining
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "tariff-expire" },
                                      [
                                        _c("div", { staticClass: "label" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("lk.account.expires")
                                              ) +
                                              " "
                                          ),
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "value" },
                                          [
                                            _vm.selectedTab === 0
                                              ? [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.userData
                                                          .tariffDaysLeft
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.$tc(
                                                          "lk.subscribe.modal.daysLeftDetail",
                                                          _vm.userData
                                                            .tariffDaysLeft
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              : [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.dayLeft) +
                                                      " "
                                                  ),
                                                ],
                                          ],
                                          2
                                        ),
                                      ]
                                    ),
                                    _vm.hasTariffWeb3
                                      ? _c(
                                          "div",
                                          { staticClass: "tariff-name mt-1" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "value font-weight-bold",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-uppercase",
                                                    staticStyle: {
                                                      color: "var(--warning)",
                                                    },
                                                  },
                                                  [_vm._v("WEB3:")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "var(--success)",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "lk.payments.paid"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  !_vm.userData.tariffIsActive && _vm.selectedTab == 0
                    ? _c(
                        "CCard",
                        {
                          staticClass:
                            "shadow-none referral-info-card mb-0 empty-tariff",
                          attrs: { color: "second-card-bg" },
                        },
                        [
                          _c(
                            "CCardBody",
                            { staticClass: "referral-info-card__body" },
                            [
                              _c("div", { staticClass: "label" }, [
                                _vm._v(_vm._s(_vm.$t("lk.account.noTariff"))),
                              ]),
                              _c(
                                "router-link",
                                {
                                  staticClass: "value",
                                  attrs: { to: { name: "lk-subscribe" } },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("lk.account.gotoTariffs"))
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex flex-column" },
                [
                  _c("ReferralInfoCard", {
                    staticClass: "w-100 balanceCard",
                    attrs: {
                      menuItems: [
                        {
                          show: true,
                          name: _vm.$t("lk.account.tabMainScore"),
                          iconName: "cil-dollar",
                        },
                        {
                          show: true,
                          name: _vm.$t("lk.account.tabReferalScore"),
                          iconName: "cil-user",
                        },
                      ],
                    },
                    on: {
                      selectedTab: function ($event) {
                        _vm.balanceTab = $event
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.balanceTab
                                    ? _vm.$t("lk.account.refBalance")
                                    : _vm.$t("lk.account.currentBalance")
                                ) +
                                " "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "value",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-column align-items-center",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.balanceTab
                                        ? _vm.referralsInfo.bonusBalance
                                        : _vm.balance
                                    ) +
                                    " "
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "balance-icon balance-icon__success",
                                  },
                                  [_vm._v(" USDT ")]
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c(
                    "CButton",
                    {
                      staticClass: "p2 cardButton mt-3",
                      attrs: { color: "primary" },
                      on: { click: _vm.topUpClick },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("lk.account.fillBalance")) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _c("modal-google2fa", {
        attrs: { show: _vm.showGoogle2faModal },
        on: {
          "update:show": function ($event) {
            _vm.showGoogle2faModal = $event
          },
        },
      }),
      _c("modal-buy-bonuses", {
        attrs: { "buy-popup-state": _vm.buyPopupState },
        on: {
          "update:buyPopupState": function ($event) {
            _vm.buyPopupState = $event
          },
          "update:buy-popup-state": function ($event) {
            _vm.buyPopupState = $event
          },
          errorPopup: _vm.handleError,
        },
      }),
      _c(
        "CModal",
        {
          attrs: {
            title: `${_vm.$t("lk.subscribe.modal.goToBuy")}`,
            show: _vm.popupBlocked,
            closeOnBackdrop: false,
            centered: "",
          },
          on: {
            "update:show": function ($event) {
              _vm.popupBlocked = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "w-100" },
                    [
                      _c(
                        "CButton",
                        {
                          attrs: { color: "dark" },
                          on: {
                            click: function ($event) {
                              _vm.popupBlocked = false
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("lk.subscribe.modal.cancel")) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("p", [_vm._v(_vm._s(_vm.$t("lk.subscribe.modal.pageNoOpen")))]),
          _c(
            "CLink",
            {
              staticClass: "text-primary",
              attrs: { target: "_blank", href: _vm.paymentUrl },
              on: {
                click: function ($event) {
                  _vm.popupBlocked = false
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.paymentUrl) + " ")]
          ),
        ],
        1
      ),
      _c("modal-password", {
        attrs: { "password-modal": _vm.passwordModal },
        on: {
          "update:passwordModal": function ($event) {
            _vm.passwordModal = $event
          },
          "update:password-modal": function ($event) {
            _vm.passwordModal = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }