<template>
  <CModal
    :title="`${$t('lk.account.configure2FA')}`"
    centered
    :show="show"
    @update:show="$emit('update:show', $event)"
  >
    <div>
      <template v-if="isConfiguring">
        <div class="2fa-configure" v-if="step === 1">
          <div class="info mb-2">
            {{ $t('lk.account.modal2FA.title') }}
          </div>
          <div class="font-weight-bold font-xl text-center">
            {{ $t('lk.account.modal2FA.secretTitle') }}
          </div>
          <div class="text-center" ref="code">
            {{ secret }}
          </div>
          <CButton
            color="primary"
            class="d-block ml-auto mr-auto mt-2 mb-2"
            variant="outline"
            @click="copyCode"
          >
            {{ $t('lk.account.modal2FA.copyButton') }}
          </CButton>
          <div v-if="qr_code" class="mb-2">
            <img style="width: 175px;height: 175px;margin: auto;display: block;" :src="`data:image/png;base64,${qr_code}`"/>
          </div>
          <div class="info">
            <div class="title">{{ $t('lk.account.modal2FA.install.title') }}</div>
            <ul>
              <li>{{ $t('lk.account.modal2FA.install.inst1') }}</li>
              <li>{{ $t('lk.account.modal2FA.install.inst2') }}</li>
              <li>{{ $t('lk.account.modal2FA.install.inst3') }}</li>
            </ul>
            <div class="warn">{{ $t('lk.account.modal2FA.install.saveKey') }}</div>
          </div>
        </div>
        <div class="2fa-configure" v-if="step === 2">
          <div class="info">
            <div class="font-weight-bold font-xl text-center">{{ $t('lk.account.modal2FA.enterCode') }}</div>
            <div class="subtitle">{{ $t('lk.account.6digitCode') }}</div>
          </div>
          <CForm @submit.prevent="incStep">
            <CInput v-model="user_code" :disabled="loadingRequest" :placeholder="$t('lk.account.modal2FA.enterCodeMin')"/>
          </CForm>
        </div>
      </template>
      <template v-else>
        <div class="2fa-enabled" v-if="google2faState">
          {{ $t('lk.account.modal2FA.2FAenabled') }}
        </div>
        <div class="2fa-disabled" v-else>
          {{ $t('lk.account.modal2FA.2FAdisabled') }}
        </div>
      </template>
    </div>
    <template v-slot:footer>
      <div class="w-100 d-flex">
        <template v-if="isConfiguring">
          <CLoadingButtonCustom
            color="primary"
            class="mr-2"
            :loading="loadingRequest"
            :disabled="loadingRequest"
            @click.native="incStep"
          >
            {{ $t('lk.account.modal2FA.continue') }}
          </CLoadingButtonCustom>
          <CLoadingButtonCustom
            color="secondary"
            v-if="step > 1"
            :disabled="loadingRequest"
            @click.native="decStep"
          >
            {{ $t('lk.account.modal2FA.back') }}
          </CLoadingButtonCustom>
        </template>
        <CLoadingButtonCustom
          v-else
          color="primary"
          @click.native="toggle2fa"
          :disabled="loadingRequest"
          :loading="loadingRequest"
        >
          {{ google2faState ? $t('lk.account.modal2FA.disable2FA') : $t('lk.account.modal2FA.enable2FA') }}
        </CLoadingButtonCustom>
      </div>
    </template>
  </CModal>
</template>

<script>
import axios from "axios";

export default {
  name: "Google2fa",
  props: {
    show: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      loadingRequest: false,
      qr_code: null,
      secret: null,
      step: 0,
      MAX_STEP: 2,
      user_code: ''
    }
  },
  computed: {
    isConfiguring() {
      return this.step > 0
    },
    google2faState() {
      return this.$store.state.user.userData.google2faEnabled
    }
  },
  methods: {
    copyCode() {
      this.selectText(this.$refs.code); // e.g. <div ref="text">
      document.execCommand("copy");
      this.$toastr.success(this.$t('lk.account.modal2FA.toast.codeCopied'))
    },
    initUserData() {
      this.$store.dispatch('user/getCurrent')
      this.user_code = ''
    },
    incStep() {
      if (this.step === this.MAX_STEP + 1) return
      if (this.step === 2) {
        this.validateCode()
      } else {
        this.step++
      }
    },
    decStep() {
      if (this.step === 1) return
      this.step--
    },
    validateCode() {
      this.loadingRequest = true
      axios.post('/api/v2/2fa/validate', {
        code: this.user_code
      })
      .then(({data}) => {
        if (data?.valid) {
          this.initUserData()
          this.$toastr.success(this.$t('lk.account.modal2FA.toast.2FAenabled'))
          this.$emit('update:show', false)
          this.step = 0
        } else {
          this.$toastr.error(this.$t('lk.account.modal2FA.toast.errorConfirm'))
        }
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        this.loadingRequest = false
      })
    },
    toggle2fa() {
      this.loadingRequest = true
      if (this.google2faState) {
        axios.post('/api/v2/2fa/disable')
          .then(({data}) => {
            if (data?.google2fa === false) {
              this.$toastr.success(this.$t('lk.account.modal2FA.toast.2FAdisabled'))
              this.$emit('update:show', false)
            }
          })
          .catch((e) => {
            this.$toastr.error(this.$t('lk.account.modal2FA.toast.errorChanging'))
            console.error(e)
          })
          .finally(() => {
            this.initUserData()
            this.loadingRequest = false
          })
      } else {
        axios.post('/api/v2/2fa/enable')
          .then(({data}) => {
            this.qr_code = data?.qr_code
            this.secret = data?.secret
            this.step = 1
          })
          .catch((e) => {
            this.$toastr.error(this.$t('lk.account.modal2FA.toast.errorChanging'))
            console.error(e)
          })
          .finally(() => {
            this.initUserData()
            this.loadingRequest = false
          })
      }
    },
    selectText(element) {
      let range;
      if (document.selection) {
        // IE
        range = document.body.createTextRange();
        range.moveToElementText(element);
        range.select();
      } else if (window.getSelection) {
        range = document.createRange();
        range.selectNode(element);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
      }
    },
  }
}
</script>

<style scoped>

</style>
