<template>
  <div class="">
    <div class="header">
      <div class="title-wrap mr-auto">
        <h1 class="title mb-0 mr-3">
          {{ $t("lk.header.account") }}
        </h1>
        <div class="d-flex flex-column">
          <div
          v-if="$store.getters['user/telegramVerify'] && !$store.getters['user/isGuest']"
          class="d-flex align-items-center">
            <CIcon name="cil-check" class="text-success" />
            <span class="ml-1 mb-0 verify-message">{{
              $t("lk.account.verifiedTelegram")
            }}</span>

            <v-popover
              :handleResize="false"
              :open="isOpen"
              trigger="manual"
              container=".c-app"
              placement="bottom"
            >
            <span
            class="rounded-circle tg__popover-wrap d-inline-flex justify-content-center ml-2"
            @click="isOpen = !isOpen">
              i
            </span>

              <template slot="popover">
                <div class="tooltip-inner d-flex flex-column justify-content-start">
                  <div class="d-flex flex-column align-items-start">
                    <span v-if="userData.telegram?.chat?.id">
                      ID: {{ userData.telegram.chat.id }}
                    </span>
                    <span v-if="userData.telegram?.chat?.first_name">
                      {{$t('lk.training.curatorTable.name')}}: {{ userData.telegram?.chat?.first_name }}
                    </span>
                    <span v-if="userData.telegram?.chat?.username">
                      {{$t('general.nick')}}: {{ userData.telegram?.chat?.username }}
                    </span>
                  </div>
                  <CLoadingButtonCustom
                    v-if="userData.getAccess(1, true)"
                    class="tg__unbind-btn mt-3"
                    color="primary"
                    :loading="loadingUnbindTg"
                    :disabled="loadingUnbindTg"
                    @click.native="unbindTg"
                  >
                    {{ $t('general.unbind') }}
                  </CLoadingButtonCustom>
                </div>
              </template>
            </v-popover>
          </div>
          <div v-if="google2faState">
            <CIcon name="cil-check" class="text-success" />
            <span class="ml-1 mb-0 verify-message">{{
              $t("lk.account.verified2FA")
            }}</span>
          </div>
          <div v-if="isWeb3Auth">
            <CIcon name="cil-check" class="text-success" />
            <span class="ml-1 mb-0 verify-message">
              Web3
            </span>
          </div>
        </div>
      </div>
      <!-- <CButton
        color="light"
        :href="$t('aboutUs.tgTTTools')"
        class="mr-2"
        target="_blank"
      >
        {{ $t("lk.account.telegram") }}
      </CButton>

      <CButton
        color="light"
        :href="$t('lk.account.feedbackLink')"
        class="mr-2"
        target="_blank"
      >
        {{ $t("lk.account.feedback") }}
      </CButton> -->

      <CButton
        v-if="showPromoCodeBtn"
        color="light"
        class=""
        @click="activateTrends"
      >
        <CIcon name="key" class="icon" />
        {{ $t("lk.account.promoCodeBtn") }}
      </CButton>
    </div>

    <div class="d-flex justify-content-between blockWarpper">
      <div>
        <CForm @submit.prevent="save">
          <CInput
            class="cinput"
            ref="nameInput"
            :value="username"
            @input="nameInputHandler"
            :label="$t('lk.account.formName')"
            :placeholder="$t('lk.account.formName')"
          >
            <template #prepend-content>
              <CIcon name="cil-user" />
            </template>
          </CInput>

          <CInput
            class="cinput"
            v-model="email"
            :type="showEmail ? 'text' : 'password'"
            :label="$t('lk.account.formEmail')"
            :isValid="() => this.isEmailAvailableToChange()"
            :invalidFeedback="$t('lk.account.changeEmailHint')"
            readonly
            onfocus="this.removeAttribute('readonly')"
            autoComplete="off"
            placeholder="email"
          >
            <template #prepend-content>
              <CIcon name="mail" />
            </template>
            <template #append-content>
              <CIcon
                v-if="showEmail"
                name="eye-close"
                class="cursor-pointer"
                @click.native="showEmail = !showEmail"
              />
              <CIcon
                v-else
                name="eye-open"
                class="cursor-pointer"
                @click.native="showEmail = !showEmail"
              />
            </template>
          </CInput>

          <CInput
            v-model="code"
            v-if="google2faState && showSaveBtn"
            :placeholder="$t('lk.account.6digitCode')"
            :label="$t('lk.account.enterCodeFromApp')"
            class="cinput"
          />

          <CLoadingButtonCustom
            v-if="showSaveBtn"
            color="primary"
            class="cbtn"
            :loading="loadingRequest"
            :disabled="loadingRequest"
            type="submit"
          >
            {{ $t("lk.account.save") }}
          </CLoadingButtonCustom>
        </CForm>

        <hr class="separator" />

        <CRow class="subactions">
          <CCol class="ccol gap-3">
            <CButton
              color="primary"
              class=""
              @click="changePasswordClick"
            >
              {{ $t("lk.account.changePassword") }}
            </CButton>

            <confirm-tg
            :isRequestPassed="$store.getters['user/telegramVerify']"
            :isShow="!$store.getters['user/telegramVerify']"
            :text="$t('lk.account.connectTelegram')"
            color="primary"
            class="ml-2 tg-button" />

            <CButton
              color="primary"
              class="ml-2"
              @click="google2FaClick"
            >
              {{ $t("lk.account.configure2FA") }}
            </CButton>
            <CButton
              color="primary"
              class="ml-2"
              @click="web3ConnectHandler"
              v-if="!!Metamask.ethereum && !isWeb3Auth"
            >
              Web3
            </CButton>
          </CCol>
        </CRow>
        </div>
        <div class="d-flex flex-column">
        <div class="d-flex cardWrapper">
        <div class="mr-4 firsCard">
          <CCard
            class="shadow-none referral-info-card mb-0"
            :class="{'referral-active': !currentTariffEdu}"
            color="second-card-bg"
          >
            <LinkGroupSolid
              v-if="currentTariffEdu && userData.tariffIsActive"
              @selected="selectedTab = $event"
              :activeTab="selectedTab"
              :dropdownMenuItems="[
                { show: true, name: $t('lk.account.tabSubscription'), iconName: 'cil-dollar', },
                { show: true, name: $t('lk.account.tabTraining'), iconName: 'cilEducation' },
              ]"
            />
            <CCardBody v-if="selectedTab == 0 ? userData.tariffIsActive : currentTariffEdu" class="referral-info-card__body">
              <template v-if="isTariffWeb3">
                <div class="tariff-name mb-1">
                  <div class="value font-weight-bold">
                    <span class="text-uppercase" style="color: var(--warning)">WEB3:</span> <span style="color: var(--success)">{{ $t("lk.payments.paid") }}</span>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="tariff-name mb-1">
                  <div class="label">
                    {{ $t("lk.account.currentTariff") }}
                  </div>
                  <div
                    class="value text-uppercase font-weight-bold"
                    :style="
                    selectedTab == 0
                      ? 'color: ' + userData.tariff.color
                      : currentTariffEdu?.tariff?.id == 'standard_edu'
                      ? 'color: #3686F6'
                      : 'color: #FCB81C'
                  "
                  >
                    {{ tariffTitle }}
                  </div>
                </div>
                <div class="tariff-expire mb-1">
                  <div class="label">
                    {{ $t("lk.account.dueTo") }}
                  </div>
                  <div class="value">
                    {{selectedTab === 0 ? dateExpiredFormatted : dateExpiredFormattedTraining }}
                  </div>
                </div>
                <div class="tariff-expire">
                  <div class="label">
                    {{ $t("lk.account.expires") }}
                  </div>
                  <div class="value">
                    <template v-if="selectedTab === 0">
                      {{ userData.tariffDaysLeft }}
                      {{ $tc("lk.subscribe.modal.daysLeftDetail", userData.tariffDaysLeft) }}
                    </template>
                    <template v-else>
                      {{ dayLeft }}
                    </template>
                  </div>
                </div>
                <div class="tariff-name mt-1" v-if="hasTariffWeb3">
                  <div class="value font-weight-bold">
                    <span class="text-uppercase" style="color: var(--warning)">WEB3:</span> <span style="color: var(--success)">{{ $t("lk.payments.paid") }}</span>
                  </div>
                </div>
              </template>

            </CCardBody>
          </CCard>

          <CCard
            v-if="!userData.tariffIsActive && selectedTab == 0"
            class="shadow-none referral-info-card mb-0 empty-tariff"
            color="second-card-bg"
          >
            <CCardBody class="referral-info-card__body">
              <div class="label">{{ $t("lk.account.noTariff") }}</div>
              <router-link class="value" :to="{ name: 'lk-subscribe' }">{{
                $t("lk.account.gotoTariffs")
              }}</router-link>
            </CCardBody>
          </CCard>
        </div>
        <div class="d-flex flex-column">
          <ReferralInfoCard
          :menuItems="[
            { show: true, name: $t('lk.account.tabMainScore'), iconName: 'cil-dollar', },
            { show: true, name: $t('lk.account.tabReferalScore'), iconName: 'cil-user' },
          ]"
          @selectedTab="balanceTab = $event"
          class="w-100 balanceCard">
            <template #title>
              {{ balanceTab ? $t("lk.account.refBalance") : $t("lk.account.currentBalance") }}
            </template>
            <template #value>
              <div class="d-flex flex-column align-items-center">
                {{balanceTab ? referralsInfo.bonusBalance : balance }}
                <span class="balance-icon balance-icon__success"> USDT </span>
              </div>
            </template>
          </ReferralInfoCard>
          <CButton
            color="primary"
            class="p2 cardButton mt-3"
            @click="topUpClick"
          >
          {{ $t("lk.account.fillBalance") }}
        </CButton>
        </div>
        </div>
        <!-- <CButton
            color="primary"
            class="p2 mt-3 align-self-end cardButton"
            @click="buyPopupState = true"
          >
          {{ $t("lk.account.fillBalance") }}
        </CButton> -->
      </div>
    </div>

    <modal-google2fa :show.sync="showGoogle2faModal" />
    <modal-buy-bonuses
      :buy-popup-state.sync="buyPopupState"
      @errorPopup="handleError"
    />
    <CModal
      :title="`${$t('lk.subscribe.modal.goToBuy')}`"
      :show="popupBlocked"
      :closeOnBackdrop="false"
      @update:show="popupBlocked = $event"
      centered
    >
      <p>{{ $t("lk.subscribe.modal.pageNoOpen") }}</p>
      <CLink
        class="text-primary"
        target="_blank"
        :href="paymentUrl"
        @click="popupBlocked = false"
      >
        {{ paymentUrl }}
      </CLink>

      <template v-slot:footer>
        <div class="w-100">
          <CButton color="dark" @click="popupBlocked = false">
            {{ $t("lk.subscribe.modal.cancel") }}
          </CButton>
        </div>
      </template>
    </CModal>

    <modal-password :password-modal.sync="passwordModal" />
  </div>
</template>

<script>
import ModalPassword from "@/components/account/ModalPassword";
import cloneDeep from "lodash/cloneDeep";
import axios from "axios";
import ReferralInfoCard from "@/components/lk/referrals/ReferralInfoCard";
import ModalBuyBonuses from "@/components/account/ModalBuyBonuses";
import ModalGoogle2fa from "@/components/account/ModalGoogle2fa";
import moment from "moment";
import ConfirmTg from "@/components/account/ConfirmTg";
import LinkGroupSolid from "@/pages/lk/LinkGroupSolid";
import MetamaskConnector from "@/assets/js/Metamask.js"
import RabbyKit from "@/assets/js/rabby.js"
import {mapActions} from "vuex"

export default {
  name: "Account",
  components: {
    ModalBuyBonuses,
    ModalPassword,
    ReferralInfoCard,
    ModalGoogle2fa,
    ConfirmTg,
    LinkGroupSolid,
  },
  data() {
    return {
      loadingUnbindTg: false,
      isOpen: false,
      passwordModal: false,
      username: "",
      usernameInit: "",
      email: "",
      emailInit: "",
      loadingRequest: false,
      showEmail: false,
      buyPopupState: false,
      popupBlocked: false,
      paymentUrl: "",
      balance: 0,
      showGoogle2faModal: false,
      code: "",
      selectedTab: 0,
      balanceTab:0,
      referralsInfo: {
        count: 0,
        countPayed: 0,
        bonusBalance: 0,
        allBonus: 0
      },
      isWeb3Auth: false,
      Metamask: new MetamaskConnector(),
      RabbyKit: new RabbyKit()
    };
  },
  async created() {
    await this.initUserData();
  },
  mounted() {
    this.updateInfo();
  },
  methods: {
    ...mapActions({
      toggleShowLoginModal: 'user/toggleShowLoginModal',
    }),
    topUpClick(){
      if(this.userData?.isGuest){
        this.toggleShowLoginModal({show: true})
        return
      }
      this.buyPopupState = true
    },
    google2FaClick(){
      if(this.userData?.isGuest){
        this.toggleShowLoginModal({show: true})
        return
      }
      this.showGoogle2faModal = true
    },
    changePasswordClick(){
      if(this.userData?.isGuest){
        this.toggleShowLoginModal({show: true})
        return
      }
      this.passwordModal = true
    },
    async web3ConnectHandler(){
      if(this.userData?.isGuest){
        this.toggleShowLoginModal({show: true})
        return
      }
      this.RabbyKit.init(this.$store.getters.darkMode ? 'dark' : '', this.initUserData)
      this.RabbyKit.kit.open()
      // const message = `By signing this message, you are verifying your EVM wallet, which gives us the ability to READ ONLY your NFTs. In the future, we may gifting NFTs and providing access to our private video materials.`
      // await this.Metamask.connect()
      // await this.Metamask.changeNetwork()
      // const address = this.Metamask.accounts[0]
      // let signature = await this.Metamask.signMessage(
      //   message,
      //   address
      // );
      // let isValid = this.Metamask.verifyMessage({signature, message, address})
      // if(isValid){
      //   this.loadingRequest = true;
      //   this.$store
      //   .dispatch("user/updateSelf", {
      //     meta_wallet: address
      //   })
      //   .then(async ({ status }) => {
      //     if (status < 300) {
      //       await this.initUserData();
      //       this.$toastr.success(this.$t("general.dataChanged"));
      //     }
      //   })
      //   .finally(() => {
      //     this.loadingRequest = false;
      //   });
      // }
    },
    unbindTg(){
      if(this.userData?.isGuest){
        return
      }
      this.loadingUnbindTg = true
      const url = 'api/v1/drop-tg-binding'
      axios.post(url)
        .then(({status}) => {
          if (status < 300) {
            this.isOpen = false
            this.$store.dispatch("user/getCurrent");
            this.$toastr.success(this.$t("lk.account.unbindTgMessage"));
          }
        })
        .finally(() => {
          this.loadingUnbindTg = false
        })
    },
    nameInputHandler(value){
      const kirilianRegex = /[а-яА-Я]/g;
      const validValue = value.replace(kirilianRegex, "");
      this.username = validValue
      this.$refs.nameInput.state = validValue
    },
    isEmailAvailableToChange() {
      if (!this.google2faState) {
        // если отключена 2fa
        // проверяем то, что юзер начал редактировать имейл
        if (this.email === this.emailInit) {
          return undefined; // отключаем валидацию
        } else {
          return false; // показываем ошибку
        }
      }
      return undefined; // отключаем валидацию
    },
    handleError(error) {
      this.popupBlocked = true;
      this.paymentUrl = error.message;
    },
    async initUserData() {
      if(!this.userData?.isGuest){
        await this.$store.dispatch('user/getCurrent')
      }
      this.isWeb3Auth = !!cloneDeep(this.$store.state.user.userData.meta_wallet);
      this.username = cloneDeep(this.$store.state.user.userData.name);
      this.usernameInit = cloneDeep(this.$store.state.user.userData.name);

      this.email = cloneDeep(this.$store.state.user.userData.email);
      this.emailInit = cloneDeep(this.$store.state.user.userData.email);
      this.balance = cloneDeep(this.$store.state.user.userData.balance);
    },
    save() {
      if(this.userData?.isGuest){
        return
      }
      this.loadingRequest = true;
      const data = {
        name: this.username,
        email: this.email,
      };
      if (this.google2faState) {
        data.code = this.code;
      }
      this.$store
        .dispatch("user/updateSelf", data)
        .then(({ status }) => {
          if (status < 300) {
            this.initUserData();
            this.$toastr.success(this.$t("general.dataChanged"));
          }
        })
        .finally(() => {
          this.loadingRequest = false;
          this.code = "";
        });
    },
    activateTrends() {
      if(this.userData?.isGuest){
        return
      }
      axios.post("api/v1/tariffs/trends").then(({ data, status }) => {
        if (status < 300 && data.enable_trends) {
          this.$toastr.success(
            data.message || this.$t("lk.account.activateTrendsSuccessfully")
          );
          this.$store.dispatch("user/getCurrent");
        }
      });
    },
    updateInfo () {
      if(this.userData?.isGuest){
        return
      }
      axios.get('/api/v2/referrals/info')
        .then((res) => {
          /** @type UserReferralInfoResource */
          if(res.data.data) {
            const data = res.data.data;
            this.referralCode = data.referral_code;
            this.showReferralCodeEdit = true;
            this.referralsInfo = {
              count: data.referrals_count,
              countPayed: data.referrals_payed,
              bonusBalance: parseFloat(data.bonus_balance).toFixed(2),
              allBonus: data.bonus_all_time
            };
          }
        });
    }
  },
  computed: {
    tariffTitle(){
      if(this.selectedTab === 0){
        return this.userData.tariff.name[this.$root.$i18n.locale]
      }
      let label = this.currentTariffEdu?.tariff?.name?.['en']
      if(this.currentTariffEdu?.has_mentor && this.currentTariffEdu?.tariff?.id !== 'web3_edu'){
        label += " + MENTORSHIP"
      }
      return label
    },
    currentTariffEdu(){
      //@todo
      return null//this.$store.state.user.userData?.events_users?.[0]
    },
    isTariffWeb3(){
      return this.selectedTab === 1 && this.currentTariffEdu?.tariff?.id === 'web3_edu'
    },
    hasTariffWeb3(){
      return this.selectedTab === 1 && this.$store.state.user.userData?.hasTariffWeb3
    },
    google2faState() {
      return this.$store.state.user.userData.google2faEnabled;
    },
    userData() {
      return this.$store.state.user.userData;
    },
    dateExpiredFormatted() {
      if (!this.userData.tariffIsActive && !this.userData.tariffExpired) {
        return false;
      }
      moment.locale(this.$store.state.settings.global.locale);
      return moment(this.userData.tariffExpired).format("L");
    },
    dateExpiredFormattedTraining() {
      if (!this.currentTariffEdu) return false;

      moment.locale(this.$store.state.settings.global.locale);
      let date = moment(this.currentTariffEdu?.finished_at).format("L");
      if(this.currentTariffEdu?.has_mentor){
        moment.locale(this.$store.state.settings.global.locale);
        let mentorDate = moment(this.currentTariffEdu?.mentor_finished_at).format("L");
        date = `${date} / ${mentorDate}`
      }
      return date
    },
    dayLeft () {
      if(!this.currentTariffEdu) {
        return false
      }
      let toDay = new Date()
      let finish = moment(this.currentTariffEdu?.finished_at, 'YYYY-MM-DD HH:mm:ss').toDate()
      let daysLeft = Math.round((finish - toDay)/1000/60/60/24)
      let daysLeftStr = `${daysLeft} ${this.$tc("lk.subscribe.modal.daysLeftDetail", daysLeft)}`
      if(this.currentTariffEdu?.has_mentor){
        daysLeftStr = `${daysLeftStr} / ${this.currentTariffEdu?.mentor_days_left} ${this.$tc("lk.subscribe.modal.daysLeftDetail", this.currentTariffEdu?.mentor_days_left)}`
      }
      return daysLeftStr
    },
    showSaveBtn() {
      return (
        this.username !== this.usernameInit || this.email !== this.emailInit
      );
    },
    showPromoCodeBtn() {
      return this.$store.getters["user/getPermissions"]("view_trends_tariff");
      // const days = this.$store.state.user.userData?.tariffDaysLeft
      // if (!days) return false
      //
      // return (
      //   this.$store.getters['user/tariff'] === 'delta_screener' &&
      //   this.$store.getters['user/telegramVerify'] &&
      //   days >= 350
      // )
    },
  },
};
</script>


<style lang="scss" scoped>
//@import "@/assets/scss/style";

.balanceCard{
  min-height: 211px;
}
.header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  .title {
    font-size: 1.25rem;
    @include media-breakpoint-up(xl) {
      font-size: 2.25rem;
    }
  }

  @include media-breakpoint-up(sm) {
    margin-bottom: 1.75rem;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 2.25rem;
  }
}

.separator {
  margin: 2.5rem 0;
}

.icon {
  margin: 0;
}

.subactions {
  .ccol {
    display: flex;
  }

  @include media-breakpoint-down(xs) {
    .ccol {
      flex-direction: column;
      align-items: center;
      gap: 10px;
      button{
        margin: 0 !important;
      }
    }
  }
}

.cinput /deep/ .form-control,
.cbtn {
  height: 2.75rem;
}

.tg-button {
  width: 13rem;
}

.verify-message {
  font-size: 0.75rem;
}

.title-wrap {
  @include media-breakpoint-up(sm) {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;
    align-items: center;
  }
}

.balance-icon {
  font-size: 25px;

  &__success {
    color: var(--success);
    font-weight: bold;
  }
}

.label {
  font-size: 15px;
  line-height: 1.4;
  color: var(--white);
}
.value {
  font-weight: bold;
}
.empty-tariff {
  min-height: 11rem;
}

.referral-active {
  margin-top: 36px
}

.blockWarpper{
  @include media-breakpoint-down(lg) {
    flex-direction: column;
  }
}
.cardWrapper{
  @include media-breakpoint-down(lg) {
    flex-direction: column;
  }
}
.firsCard{
  @include media-breakpoint-down(lg) {
    margin-right: 0 !important;
    margin-bottom: 20px;
    margin-top: 30px;
  }
}
.cardButton{
  width: 100%;
  max-width: 275px;
  @include media-breakpoint-down(lg) {
    max-width: initial;
    width: 100% !important;
  }
}
.tg{
  &__popover-wrap{
    cursor: pointer;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 1rem;
    flex-shrink: 0;
    height: 1rem;
    font-size: 0.75rem;
    background-color: var(--second-card-bg);
  }
  &__unbind-btn{
    width: fit-content;
    height: fit-content;
  }
}
</style>
