var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CModal",
    {
      attrs: {
        "add-content-classes": "ccontent",
        title: `${_vm.$t("lk.bonuses.buyBonusesTitle")}`,
        centered: "",
        show: _vm.buyPopupState,
        closeOnBackdrop: false,
      },
      on: {
        "update:show": function ($event) {
          return _vm.$emit("update:buyPopupState", $event)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "CRow",
                { staticClass: "w-100 m-0" },
                [
                  _c(
                    "CCol",
                    { staticClass: "px-0", attrs: { cols: "12", xl: "5" } },
                    [
                      _c(
                        "CLoadingButtonCustom",
                        {
                          staticClass: "cbtn",
                          attrs: {
                            color: "primary",
                            loading: _vm.loading,
                            disabled: _vm.loading,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.buyRequest.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("lk.bonuses.buyBonusesButton")) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "CForm",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.buyRequest.apply(null, arguments)
            },
          },
        },
        [
          _c("CInput", {
            staticClass: "cinput",
            attrs: {
              placeholder: `${_vm.$t("lk.bonuses.buyBonusesInput")}`,
              label: `${_vm.$t("lk.bonuses.buyBonusesInput")}`,
              type: "number",
              min: "0",
              autocomplete: "off",
            },
            model: {
              value: _vm.amount,
              callback: function ($$v) {
                _vm.amount = $$v
              },
              expression: "amount",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }