var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CModal",
    {
      attrs: {
        title: `${_vm.$t("lk.account.configure2FA")}`,
        centered: "",
        show: _vm.show,
      },
      on: {
        "update:show": function ($event) {
          return _vm.$emit("update:show", $event)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "w-100 d-flex" },
                [
                  _vm.isConfiguring
                    ? [
                        _c(
                          "CLoadingButtonCustom",
                          {
                            staticClass: "mr-2",
                            attrs: {
                              color: "primary",
                              loading: _vm.loadingRequest,
                              disabled: _vm.loadingRequest,
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.incStep.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("lk.account.modal2FA.continue")) +
                                " "
                            ),
                          ]
                        ),
                        _vm.step > 1
                          ? _c(
                              "CLoadingButtonCustom",
                              {
                                attrs: {
                                  color: "secondary",
                                  disabled: _vm.loadingRequest,
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.decStep.apply(null, arguments)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("lk.account.modal2FA.back")) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    : _c(
                        "CLoadingButtonCustom",
                        {
                          attrs: {
                            color: "primary",
                            disabled: _vm.loadingRequest,
                            loading: _vm.loadingRequest,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.toggle2fa.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.google2faState
                                  ? _vm.$t("lk.account.modal2FA.disable2FA")
                                  : _vm.$t("lk.account.modal2FA.enable2FA")
                              ) +
                              " "
                          ),
                        ]
                      ),
                ],
                2
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        [
          _vm.isConfiguring
            ? [
                _vm.step === 1
                  ? _c(
                      "div",
                      { staticClass: "2fa-configure" },
                      [
                        _c("div", { staticClass: "info mb-2" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("lk.account.modal2FA.title")) +
                              " "
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "font-weight-bold font-xl text-center",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("lk.account.modal2FA.secretTitle")
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c("div", { ref: "code", staticClass: "text-center" }, [
                          _vm._v(" " + _vm._s(_vm.secret) + " "),
                        ]),
                        _c(
                          "CButton",
                          {
                            staticClass: "d-block ml-auto mr-auto mt-2 mb-2",
                            attrs: { color: "primary", variant: "outline" },
                            on: { click: _vm.copyCode },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("lk.account.modal2FA.copyButton")
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _vm.qr_code
                          ? _c("div", { staticClass: "mb-2" }, [
                              _c("img", {
                                staticStyle: {
                                  width: "175px",
                                  height: "175px",
                                  margin: "auto",
                                  display: "block",
                                },
                                attrs: {
                                  src: `data:image/png;base64,${_vm.qr_code}`,
                                },
                              }),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "info" }, [
                          _c("div", { staticClass: "title" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("lk.account.modal2FA.install.title")
                              )
                            ),
                          ]),
                          _c("ul", [
                            _c("li", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("lk.account.modal2FA.install.inst1")
                                )
                              ),
                            ]),
                            _c("li", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("lk.account.modal2FA.install.inst2")
                                )
                              ),
                            ]),
                            _c("li", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("lk.account.modal2FA.install.inst3")
                                )
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "warn" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("lk.account.modal2FA.install.saveKey")
                              )
                            ),
                          ]),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.step === 2
                  ? _c(
                      "div",
                      { staticClass: "2fa-configure" },
                      [
                        _c("div", { staticClass: "info" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "font-weight-bold font-xl text-center",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("lk.account.modal2FA.enterCode"))
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "subtitle" }, [
                            _vm._v(_vm._s(_vm.$t("lk.account.6digitCode"))),
                          ]),
                        ]),
                        _c(
                          "CForm",
                          {
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                                return _vm.incStep.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c("CInput", {
                              attrs: {
                                disabled: _vm.loadingRequest,
                                placeholder: _vm.$t(
                                  "lk.account.modal2FA.enterCodeMin"
                                ),
                              },
                              model: {
                                value: _vm.user_code,
                                callback: function ($$v) {
                                  _vm.user_code = $$v
                                },
                                expression: "user_code",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            : [
                _vm.google2faState
                  ? _c("div", { staticClass: "2fa-enabled" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("lk.account.modal2FA.2FAenabled")) +
                          " "
                      ),
                    ])
                  : _c("div", { staticClass: "2fa-disabled" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("lk.account.modal2FA.2FAdisabled")) +
                          " "
                      ),
                    ]),
              ],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }