<template>
  <CModal
    add-content-classes="ccontent"
    :title="`${$t('lk.bonuses.buyBonusesTitle')}`"
    centered
    :show="buyPopupState"
    :closeOnBackdrop="false"
    @update:show="$emit('update:buyPopupState', $event)"
  >
    <CForm @submit.prevent="buyRequest">
      <CInput
        v-model="amount"
        :placeholder="`${$t('lk.bonuses.buyBonusesInput')}`"
        :label="`${$t('lk.bonuses.buyBonusesInput')}`"
        type="number"
        min="0"
        autocomplete="off"
        class="cinput"
      />
    </CForm>
    <template v-slot:footer>
      <CRow class="w-100 m-0">
        <CCol class="px-0" cols="12" xl="5">
          <CLoadingButtonCustom
            color="primary"
            class="cbtn"
            :loading="loading"
            :disabled="loading"
            @click.native="buyRequest"
          >
            {{ $t('lk.bonuses.buyBonusesButton') }}
          </CLoadingButtonCustom>
        </CCol>
      </CRow>
    </template>
  </CModal>
</template>

<script>
import axios from "axios";

export default {
  name: "ModalBuyBonuses",
  props: {
    buyPopupState: Boolean,
  },
  data() {
    return {
      amount: 0,
      loading: false,
      popupBlocked: false,
      paymentUrl: ''
    }
  },
  methods: {
    buyRequest() {
      this.loading = true
      axios.post('/api/v2/bonuses/buy', {
        amount: this.amount
      })
        .then(({data}) => {
          const {payment_url, payment_id} = data
          if (payment_url && payment_id) { // процесс оплаты
            this.$toastr.success(this.$t('lk.subscribe.switchToPayPage'))
            setTimeout(() => {
              const resWindowOpen = window.open(payment_url)
              if (!resWindowOpen) {
                this.$emit('errorPopup', {
                  'message': payment_url
                })
              }
              this.$emit('update:buyPopupState', false)
            }, 5000)
          }
        })
        .catch((e) => {
          console.error(`Ошибка покупки бонусов ${e}`)
          this.$toastr.error(this.$t('lk.subscribe.modal.errorBuyBonuses'))
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>

</style>
