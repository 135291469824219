var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showTelegramVerifyBtn
    ? _c(
        "CButton",
        {
          class: ["px-0", { "text-primary": _vm.color === "link" }],
          attrs: { color: _vm.color },
          on: { click: _vm.clickTelegramVerify },
        },
        [_vm._v(" " + _vm._s(_vm.translatedText) + " ")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }