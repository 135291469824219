var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CModal",
    {
      attrs: {
        "add-content-classes": "ccontent",
        title: _vm.$t("lk.modalPassword.title"),
        centered: "",
        show: _vm.passwordModal,
      },
      on: {
        "update:show": function ($event) {
          return _vm.$emit("update:passwordModal", $event)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "CRow",
                { staticClass: "w-100 m-0" },
                [
                  _c(
                    "CCol",
                    { staticClass: "px-0", attrs: { cols: "12", xl: "5" } },
                    [
                      _c(
                        "CLoadingButtonCustom",
                        {
                          staticClass: "cbtn",
                          attrs: {
                            color: "primary",
                            loading: _vm.loadingRequest,
                            disabled: _vm.loadingRequest,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.save.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("lk.modalPassword.change")) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("CInput", {
        staticClass: "cinput",
        attrs: {
          placeholder: _vm.$t("lk.modalPassword.current"),
          type: "password",
        },
        scopedSlots: _vm._u([
          {
            key: "prepend-content",
            fn: function () {
              return [_c("CIcon", { attrs: { name: "cil-lock-locked" } })]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.currentPassword,
          callback: function ($$v) {
            _vm.currentPassword = $$v
          },
          expression: "currentPassword",
        },
      }),
      _c("CInput", {
        staticClass: "cinput",
        attrs: {
          placeholder: _vm.$t("lk.modalPassword.new"),
          type: "password",
        },
        scopedSlots: _vm._u([
          {
            key: "prepend-content",
            fn: function () {
              return [_c("CIcon", { attrs: { name: "cil-lock-locked" } })]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.newPassword,
          callback: function ($$v) {
            _vm.newPassword = $$v
          },
          expression: "newPassword",
        },
      }),
      _c("CInput", {
        staticClass: "cinput mb-0",
        attrs: {
          placeholder: _vm.$t("lk.modalPassword.repeat"),
          type: "password",
        },
        scopedSlots: _vm._u([
          {
            key: "prepend-content",
            fn: function () {
              return [_c("CIcon", { attrs: { name: "cil-lock-locked" } })]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.newPasswordConfirmation,
          callback: function ($$v) {
            _vm.newPasswordConfirmation = $$v
          },
          expression: "newPasswordConfirmation",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }