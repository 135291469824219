<template>
  <CButton
    v-if="showTelegramVerifyBtn"
    :color="color"
    :class="['px-0', {'text-primary': color === 'link'}]"
    @click="clickTelegramVerify"
  >
    {{ translatedText }}
  </CButton>
</template>

<script>
import {mapActions} from "vuex"
export default {
  name: "ConfirmTg",
  props: {
    color: {
      type: String,
      default: 'link'
    },
    text: {
      type: String,
      default: ''
    },
    isShow: {
      type: Boolean,
      default: false
    },
    link: {
      type: String,
      default: ''
    },
    isRequestPassed: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showTelegramVerifyBtn() {
      return this.isShow || !this.$store.getters['user/telegramVerify']
    },
    translatedText() {
      return this.text || this.$t('lk.account.confirmAccount');
    },
  },
  methods: {
    ...mapActions({
      toggleShowLoginModal: 'user/toggleShowLoginModal',
    }),
    clickTelegramVerify() {
      if(this.$store.state.user.userData?.isGuest){
        this.toggleShowLoginModal({show: true})
        return
      }
      const link = this.link || this.$store.state.user.userData?.tgLink
      if(!link || link === true){
          return
      }
      window.open(link)

      this.checkTelegramVerify()
    },
    checkTelegramVerify() {
      const oldTimer = this.$store.state.user.telegramVerifyTimer
      if (oldTimer) {
        clearTimeout(oldTimer)
        this.$store.commit('user/SET_TELEGRAM_VERIFY_TIMER', null)
      }

      const timer = setTimeout(async () => {
        await this.$store.dispatch('user/getCurrent')
        if (this.isRequestPassed) {
          this.$toastr.success(this.$t('lk.account.confirmAccountSuccessfully'))
        } else {
          this.checkTelegramVerify()
        }
      }, 7000)
      this.$store.commit('user/SET_TELEGRAM_VERIFY_TIMER', timer)
    },
  }
}
</script>

<style scoped lang="scss">

</style>
