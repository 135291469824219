import { configureChains, createConfig, getAccount } from "@wagmi/core";
import { mainnet, arbitrum, bsc, optimism, polygon } from "@wagmi/core/chains";
import { publicProvider } from "@wagmi/core/providers/public";
import { alchemyProvider } from "@wagmi/core/providers/alchemy";
import { infuraProvider } from "@wagmi/core/providers/infura";
import { createModal } from "@rabby-wallet/rabbykit";
import store from "@/store";
import toastr from "toastr";
import i18n from "@/i18n"

export default class RabbyKit{
  constructor() {
    this.kit = null;
    this.account = null;
  }

  init(theme, onsuccessHandler){
    const { chains, publicClient, webSocketPublicClient } = configureChains(
      [mainnet, arbitrum, bsc, optimism, polygon],
      [
        alchemyProvider({ apiKey: process.env.VUE_APP_ALCHEMY_API_KEY }),
        infuraProvider({ apiKey: process.env.VUE_APP_INFURA_API_KEY }),
        publicProvider(),
      ]
    );

    const config = createConfig({
      autoConnect: true,
      publicClient,
      webSocketPublicClient,
    });

    this.kit = createModal({
      chains,
      wagmi: config,
      projectId: process.env.VUE_APP_WALLET_CONNECT_PROJECT_ID,
      appName: "RabbyKit",
      theme,
      onConnect: ()=> this.onConnectHandler(onsuccessHandler)
    });
  }

  onConnectHandler(onsuccessHandler){
    this.account = getAccount()
    store.dispatch("user/updateSelf", {
      meta_wallet: this.account.address
    })
    .then(async ({ status }) => {
      if (status < 300) {
        await onsuccessHandler();
        toastr.success(i18n.t("general.dataChanged"));
      }
    })
  }
}


