const util = require('ethereumjs-util');
import toastr from "toastr";

export default class MetamaskConnector {
  constructor() {
    this.networks = {
      1: 'eth',
      56: 'bsc',
      137: 'polygon',
      10: 'optimism',
      42161: 'arbitrum',
      9001: 'evmoseth',
      43114: 'avalanche',
    };

    this.ethereum = window.ethereum;

    this.accounts = [];

    this.chainId = window.ethereum && +window.ethereum.networkVersion;

    this.network = this.networks[this.chainId];
  }

  setCustomListeners() {
    this.ethereum.on('accountsChanged', function (accounts) {
      this.accounts = accounts;
    });

    this.ethereum.on('close', function () {
      this.accounts = [];
    });
  }

  async connect() {

    if (!ethereum) {
      toastr.warning(i18n.t('Metamask extension not found'))
    }


    this.setCustomListeners();

    const accounts = await this.ethereum.request({
      method: 'eth_requestAccounts',
    });

    if (accounts && accounts.length) {
      this.accounts = accounts;
      this.network = this.networks[this.chainId];
    }
  }

  async changeNetwork(network) {
    await this.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: network || '0x1' }], // chainId must be in hexadecimal numbers
    });
  }

  async signMessage(message, signer) {
    const { ERROR } = this.ethereum._log.levels;
    this.ethereum._log.disableAll(ERROR); // disable

    try {
      return await this.ethereum.request({
        method: 'personal_sign',
        params: [signer, message],
      });
    } catch (error) {
      console.warn('🔴 MetamaskConnector.error:', {
        code: error.code,
        message: error.message,
      });
    }
  }

  disconnect() {
    this.accounts = [];
  }

  verifyMessage ({ signature, message, address }) {
		if (typeof signature !== 'string') return false;
		if (signature.length == 0 || signature % 2 != 0) return false;
		if (signature.slice(0, 2) != '0x') signature = '0x' + signature;

		const { v, r, s } = util.fromRpcSig(signature);
		const hash = util.hashPersonalMessage(typeof message === 'string' ? Buffer.from(message) : message);

		if (!util.isValidSignature(v, r, s)) return false;

		let verifyPubKey;
		if (v === 27 || v === 28) {
			verifyPubKey = util.ecrecover(hash, v, r, s);
		} else {
			verifyPubKey = util.ecrecover(hash, v, r, s, parseInt((v - 35) / 2));
		}
		return address === this.public2address(verifyPubKey);
	}

  public2address (pubKey) {
		if (typeof pubKey === 'string') {
			if (pubKey.slice(0, 2) === '0x') pubKey = pubKey.slice(2);
			pubKey = Buffer.from(pubKey, 'hex');
		}
		if (pubKey.length === 33) pubKey = util.importPublic(pubKey);
		return '0x' + util.pubToAddress(pubKey).toString('hex');
	}
}
